<template>

	
	<div class="cont" id="p10">
		<div class="page-title">
			{{ program.name }} 
			<v-btn text class="">건별 결제</v-btn>
			<v-btn small class="success2" style="margin-left: 180px;" :to="{ name: 'PaymentsSmsFile'}">대량 결제<v-icon small>mdi-chevron-right</v-icon></v-btn></div>
		<div class="c-list">
			<div class="c01" style="width: 450px !important;">
				<div class="s01">
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">결제정보</div>
						</div>
						<div class="s-body">
							<div class="s-content w100">
								<div class="si-title">상품명</div>
								<div class="si-desc setting">
									<input type="text" placeholder="상품명을 입력하세요"
										:rules="[rules.max(item, 'goodsName', 150)]"
										v-model="item.goodsName" />
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">판매금액</div>
								<div class="si-desc setting">
									<input type="number" placeholder="판매금액을 입력하세요"
										:rules="[rules.max(item, 'paymentAmount', 10)]"
										v-model="item.paymentAmount" 
										/>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">고객명</div>
								<div class="si-desc setting">
									<input type="text" placeholder="고객명을 입력하세요"
										:rules="[rules.max(item, 'buyerName', 10)]"
										v-model="item.buyerName" />
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">연락처</div>
								<div class="si-desc setting">
									<input type="number" placeholder="구매자 연락처를 -없이 숫자만 입력하세요"
										:rules="[rules.max(item, 'buyerContact', 12)]"
										v-model="item.buyerContact" 
										/>
								</div>
							</div>
							<div
								v-if="false"
								class="s-content w100">
								<div class="si-title">메모</div>
								<div class="si-desc setting">
									<input type="text" placeholder="메모"
										:rules="[rules.max(item, 'memo', 250)]"
										v-model="item.memo" />
								</div>
							</div>
							<div
								v-if="false"
								class="s-content w100">
								<div class="si-title">주소요청</div>
								<div class="si-desc setting">
									<v-btn-toggle
										v-model="item.isAddressRequest"
									>
										<v-btn
											small
											value="false"
											class="mr-10"
											:class="!item.isAddressRequest ? 'success2' : 'gray'"
										>
											요청없음
										</v-btn>
										<v-btn
											small
											value="true"
											:class="item.isAddressRequest ? 'success2' : 'gray'"
										>
											주소요청
										</v-btn>
									</v-btn-toggle>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="s04">
					<a @click="save" class="focus"><div class="btn-submit" :class="{active: isSave}">등록</div></a>
				</div>
				
			</div>
		</div>
		
	</div>
</template>

<script>
	
	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	
	export default{
		name: "PaymentCard"
		,props: ['user', 'codes']
		,components: { }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[3].list[1].name
				}
				,item: {
					buyerName: ''
					,buyerContact: ''
					,goodsName: ''
					,isAddressRequest: false
					,memo: ''
					,paymentAmount: ''
				}
				,cardNumber: ['','','','']
				,rules: rules
				,isSave: false
				,view: {
					item: false
				}
			}
		}
		,computed: {
		}
		,methods: {
			save: async function(){
				if(this.isSave){
					try{
						const result = await Axios({
							method: 'post'
							,url: '/sms/approval'
							,data: this.item
							,authorize: true
						})

						if(result.success){
							this.$emit('setNotify', { type: 'success', message: '결제가 등록되었습니다'})
							this.$router.push({ name: 'PaymentsList' })
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message})
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E})
					}
				}
			}
		}
		,created: function(){
		}
		,watch: {
			item: {
				deep: true
				,handler: function(call){
					this.isSave = true
					for(const [key, value] of Object.entries(call)){
						if(!value && key != 'memo' && key != 'isAddressRequest'){
							console.log(key + ' : ' + value)
							this.isSave = false
							return
						}
					}
				}
			}
		}
	}
</script>























